import { Editor, findParentNodeClosestToPos } from '@tiptap/core'
import { Node, ResolvedPos } from 'prosemirror-model'
import { EditorState, Plugin } from 'prosemirror-state'
import { Decoration, DecorationSet } from 'prosemirror-view'

import { getStore } from 'modules/redux'
import { selectMode } from 'modules/tiptap_editor/reducer'
import { EditorModeEnum } from 'modules/tiptap_editor/types'
import { isCardNode } from 'modules/tiptap_editor/utils/nodeHelpers'

import { isCardCollapsed } from '../Card/CardCollapse'
import { isAccentCardLayoutItem } from '../Card/CardLayout/cardLayoutUtils'
import { isGalleryNode, isNodeInGallery } from '../media/Gallery'
import {
  isSmartLayoutCellNode,
  isSmartLayoutNode,
} from '../SmartLayout/isSmartLayoutCellNode'
import { isNodeSpotlightable } from '../spotlight/Spotlight'
import { AnimationsPluginKey } from './AnimationsPluginKey'
import { AnimationsState } from './AnimationsState'

export const HAS_ANIMATED_CLASS = 'animate-has-animated'
export const ANIMATABLE_ON_LOAD_CLASS = 'animatable-on-load'
export const ANIMATABLE_ON_LOAD_ACCENT_CLASS = 'animatable-on-load-accent'
export const ANIMATABLE_ON_LOAD_CONTENT_PARENT_CLASS =
  'animatable-on-load-content-parent'
export const ANIMATABLE_ON_LOAD_CONTENT_CHILD_CLASS =
  'animatable-on-load-content-child'

const isNodeAnimatable = (node: Node): boolean => {
  // Special case certain nodes, but default to checking spotlightable
  switch (node.type.name) {
    case 'card':
      return isCardCollapsed(node) === true
    case 'cardAccentLayoutItem':
      return isAccentCardLayoutItem(node)
    // Gallery and SmartLayout wrapper nodes are considered animatable
    // but the animation effect is actually applied to the children
    // The intersection observe on the wrapper nodes is what is used to
    // trigger the children animations all at once.
    case 'gallery':
    case 'smartLayout':
      return true
    default:
      return isNodeSpotlightable(node)
  }
}

const isNodeAnimatableContentChild = (node: Node, $pos: ResolvedPos) =>
  isSmartLayoutCellNode(node) || isNodeInGallery($pos)

/**
 * A helper function to add the animated class to a node that has
 * come into view. Normally this would be added by the NodeView re-rendering
 * with the added ProseMirror decorations, but in the publishing context
 * we have to do it manually (for now).
 */
export const animateStaticHTMLElement = (element: HTMLElement) => {
  element.classList.add(HAS_ANIMATED_CLASS)
  if (element.classList.contains(ANIMATABLE_ON_LOAD_CONTENT_PARENT_CLASS)) {
    // If the node is an animatable parent, add the class to any animatable children
    const animatableChildren = element.querySelectorAll(
      `.${ANIMATABLE_ON_LOAD_CONTENT_CHILD_CLASS}`
    )
    animatableChildren.forEach((child) => {
      child.classList.add(HAS_ANIMATED_CLASS)
    })
  }
}

export const getClosestAnimatablePos = (
  editor: Editor,
  pos: number
):
  | {
      pos: number
      start: number
      depth: number
      node: Node
    }
  | undefined => {
  const $pos = editor.view.state.doc.resolve(pos)
  if (!$pos) {
    return
  }
  const node = editor.state.doc.nodeAt(pos)

  if (
    node &&
    isNodeAnimatable(node) &&
    !isNodeAnimatableContentChild(node, $pos)
  ) {
    const offset = node.isLeaf || node.isAtom ? 0 : -1
    return {
      pos: $pos.pos + offset,
      start: $pos.pos,
      depth: $pos.depth,
      node,
    }
  }
  return findParentNodeClosestToPos($pos, isNodeAnimatable)
}

/**
 * Always consider all nodes animated up to the deepest animated node.
 * This prevents animations from ever happening as you scroll upwards.
 * To do so, we find the highest absolute pos in the list and add the nodeSize
 */
const getUpperAnimatedPos = (
  state: EditorState,
  pluginState: AnimationsState
) => {
  const animatedPositions = pluginState.getAnimationPositionsAbs(
    state,
    selectMode(getStore().getState()) === EditorModeEnum.SLIDE_VIEW
  )

  // Consider all nodes animated up to the deepest animated node
  const maxAnimatedPos = animatedPositions.reduce(
    (acc, pos) => Math.max(acc, pos),
    0
  )
  const maxAnimatedNode = state.doc.nodeAt(maxAnimatedPos)
  const maxAnimatedNodeSize =
    maxAnimatedNode && maxAnimatedPos > 0 ? maxAnimatedNode.nodeSize : 0

  return maxAnimatedPos + maxAnimatedNodeSize
}

export const AnimationsPlugin = () => {
  return new Plugin<AnimationsState>({
    key: AnimationsPluginKey,

    state: {
      init() {
        return new AnimationsState()
      },

      apply(transaction, pluginState, _oldEditorState, newEditorState) {
        return pluginState.apply(transaction, newEditorState)
      },
    },
    props: {
      // Add class decorations to animatable nodes
      decorations(state) {
        const decorations: Decoration[] = []
        const pluginState = this.getState(state) as AnimationsState

        if (pluginState.enabled === false) {
          return DecorationSet.create(state.doc, decorations)
        }

        const upperAnimatedPos = getUpperAnimatedPos(state, pluginState)
        state.doc.descendants((node, pos, _parent, index) => {
          const $pos = state.doc.resolve(pos)
          const isNodeOrChildAnimatable =
            isNodeAnimatable(node) || isNodeAnimatableContentChild(node, $pos)

          if (pos < upperAnimatedPos && isNodeOrChildAnimatable) {
            // Add the animate-has-animated class to the node
            decorations.push(
              Decoration.node(pos, pos + node.nodeSize, {
                class: HAS_ANIMATED_CLASS,
              })
            )
          }
          // Special case for smartLayouts & galleries
          if (isSmartLayoutNode(node) || isGalleryNode(node)) {
            // The container we observe entering the viewport for smartLayouts
            // We actually animate the cells themselves (see below)
            decorations.push(
              Decoration.node(pos, pos + node.nodeSize, {
                class: ANIMATABLE_ON_LOAD_CONTENT_PARENT_CLASS,
              })
            )
          } else if (isNodeAnimatableContentChild(node, $pos)) {
            // Each smartLayoutCell and gallery item animates when its parent
            // smartLayout/gallery comes into view.
            // We use an index to delay the animation of subsequent cells.
            decorations.push(
              Decoration.node(pos, pos + node.nodeSize, {
                class: ANIMATABLE_ON_LOAD_CONTENT_CHILD_CLASS,
                style: `--animate-index: ${index + 1}`,
              })
            )
          } else if (isNodeAnimatable(node)) {
            // The container we observe entering the viewport for all other nodes
            // This is the node that animates by default
            decorations.push(
              Decoration.node(pos, pos + node.nodeSize, {
                class: ANIMATABLE_ON_LOAD_CLASS,
              })
            )

            if (isAccentCardLayoutItem(node)) {
              // Layout accent items get a special animation
              decorations.push(
                Decoration.node(pos, pos + node.nodeSize, {
                  class: ANIMATABLE_ON_LOAD_ACCENT_CLASS,
                })
              )
            }
          }

          const isNestedCard = isCardNode(node) && $pos.depth > 1
          // Dont descend into nested cards or smartLayoutCells
          if (isNestedCard || isSmartLayoutCellNode(node)) {
            return false
          }

          return true
        })

        return DecorationSet.create(state.doc, decorations)
      },
    },
  })
}
