import { Extension } from '@tiptap/core'
import * as Y from 'yjs'

import { RelativeRange } from 'modules/tiptap_editor/utils/relativePosition'

import { AiModificationsPlugin } from './AiModificationsPlugin'
import {
  AiModificationsPluginKey,
  SetInteractionRangeAction,
} from './AiModificationsState'

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    aiModifications: {
      setInteractionRange: (rangeId: string, range: RelativeRange) => ReturnType
    }
  }
}

export type AiModificationExtensionOptions = {
  document: Y.Doc
}
export const AiModificationsExtension =
  Extension.create<AiModificationExtensionOptions>({
    name: 'aiModifications',

    onCreate() {
      this.options.document.on('afterAllTransactions', (a) => {
        const pluginState = AiModificationsPluginKey.getState(
          this.editor.view.state
        )
        if (!pluginState) {
          return
        }
        pluginState.flushMoveInstructionQueue(this.editor.view.state)
      })
    },

    addProseMirrorPlugins() {
      return [AiModificationsPlugin()]
    },

    addCommands() {
      return {
        setInteractionRange:
          (rangeId, range) =>
          ({ tr }) => {
            const payload: SetInteractionRangeAction = {
              type: 'setInteractionRange',
              rangeId,
              range,
            }
            tr.setMeta('aiModificationAction', payload)
            return true
          },
      }
    },
  })
