import { Provider as ReduxProvider } from 'react-redux'

import { StaticReduxData, useStaticEditorRedux } from './hooks'

import { EditorCore, EditorCoreProps } from '.'

export type EditorStaticProps = Omit<EditorCoreProps, 'readOnly'> & {
  reduxData?: StaticReduxData
}

/**
 * A static version of the Core Editor that is always set to read-only
 * and provides an isolated instance of the tiptap redux store for
 * each instantiated component instance.
 *
 * You might ask why we don't do a fresh redux instance for _every_ EditorCore
 * component, and it would be a good question. Generally speaking you have 1
 * global store for performance and consistency reasons, but this is a sorta
 * special case where we want to isolate this editor instance to its own store
 * so it works but doesnt interfere with the core Collaborative editor.
 *
 * The size and complexity of this store is pretty small as well.
 * We can explore other options for this in the future if this turns
 * out to not scale well when there are dozens of editors instantiated at once.
 */
export const EditorStatic = ({ ...props }: EditorStaticProps) => {
  const { reduxData, ...rest } = props
  const store = useStaticEditorRedux(reduxData)

  // By design, the EditorCore's redux hooks will find the nearest ReduxProvider
  // above them in the tree, which will be this one instead of the global.
  return (
    <ReduxProvider store={store}>
      <EditorCore
        {...rest}
        readOnly={true}
        isStatic={true}
        animationsEnabled={false}
      />
    </ReduxProvider>
  )
}
