import { yUndoPluginKey } from '@gamma-app/y-prosemirror'
import { Extension } from '@tiptap/core'
import * as Y from 'yjs'

import {
  restoreFromUndoOrRedo,
  selectLastFilmstripStateForUndo,
} from 'modules/filmstrip/reducer'
import { getStore } from 'modules/redux'

type FilmstripSelectionState = {
  selectedCardIds: string[]
  anchorId: string | null
}

export const Filmstrip = Extension.create({
  name: 'filmstrip',
  onCreate() {
    const undoManager: Y.UndoManager = yUndoPluginKey.getState(
      this.editor.state
    ).undoManager

    undoManager.on('stack-item-popped', (event) => {
      const { undoStack, redoStack } = undoManager
      const data = event.stackItem.meta.get('filmstripState') as
        | FilmstripSelectionState
        | undefined
      if (!data) {
        return
      }
      const curr = selectLastFilmstripStateForUndo(getStore().getState())

      // an undo happened add info to redo stack
      if (event.type === 'undo' && redoStack.length > 0) {
        redoStack[redoStack.length - 1].meta.set('filmstripState', curr)
      }
      // an redo happened, add info to undo stack
      else if (event.type === 'redo' && undoStack.length > 0) {
        undoStack[undoStack.length - 1].meta.set('filmstripState', curr)
      }

      getStore().dispatch(restoreFromUndoOrRedo(data))
    })
  },
})
