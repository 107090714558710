import { ySyncPluginKey } from '@gamma-app/y-prosemirror'
import { EditorState, Transaction } from 'prosemirror-state'
import * as Y from 'yjs'

import { featureFlags } from 'modules/featureFlags'
import {
  absoluteToRelativePos,
  relativeToAbsolutePos,
} from 'modules/tiptap_editor/utils/relativePosition'
import { localStore } from 'utils/storage'
import { USER_SETTINGS_CONSTANTS } from 'utils/userSettingsConstants'

import { AnimationsPluginKey } from './AnimationsPluginKey'
import { AnimationActions } from './types'

type AnimationPositionsState = {
  absPositions: number[]
  relPositions: Y.RelativePosition[]
}

const getInitialPositions = (): AnimationPositionsState => ({
  absPositions: [],
  relPositions: [],
})

export class AnimationsState {
  public enabled: boolean

  private presentModePositions: AnimationPositionsState = getInitialPositions()

  private editModePositions: AnimationPositionsState = getInitialPositions()

  constructor() {
    const lsAnimationsEnabled =
      localStore.getItem(USER_SETTINGS_CONSTANTS.editorAnimationsEnabled) ===
      'true'

    // If LaunchDarkly has initialized by the time this contructor is called,
    // we dont need the local storage value. If it hasn't, we can fallback
    // to checking the most recently known value from local storage.
    this.enabled =
      featureFlags.get('editorAnimationsEnabled') || lsAnimationsEnabled
  }

  private getPositions(isPresentMode: boolean): AnimationPositionsState {
    return isPresentMode ? this.presentModePositions : this.editModePositions
  }

  public apply(tr: Transaction, state: EditorState): this {
    const animationsAction = tr.getMeta(AnimationsPluginKey) as AnimationActions

    if (animationsAction) {
      switch (animationsAction.type) {
        case 'addAnimationPositions':
          this.addAnimationPositions(
            animationsAction.pos,
            state,
            animationsAction.isPresentMode
          )
          break
        case 'resetAnimationPositions':
          this.resetAnimationPositions(animationsAction.isPresentMode)
          break
        case 'enableAnimations':
          this.enabled = animationsAction.enabled
          break
        default:
          console.warn('AnimationsState: unknown action type', animationsAction)
          break
      }
    }

    return this
  }

  public addAnimationPositions(
    absPos: number[],
    state: EditorState,
    isPresentMode: boolean
  ): void {
    const positions = this.getPositions(isPresentMode)
    absPos.forEach((pos) => {
      positions.absPositions.push(pos)
      const relPos = absoluteToRelativePos(state, pos)
      if (relPos) {
        positions.relPositions.push(relPos)
      }
    })
  }

  public resetAnimationPositions(isPresentMode: boolean): void {
    if (isPresentMode) {
      this.presentModePositions = getInitialPositions()
    } else {
      this.editModePositions = getInitialPositions()
    }
  }

  public getAnimationPositionsAbs(
    state: EditorState,
    isPresentMode: boolean
  ): number[] {
    const ySyncState = ySyncPluginKey.getState(state)
    const positions = this.getPositions(isPresentMode)
    if (!ySyncState) {
      return positions.absPositions
    }

    return positions.relPositions
      .map((relPos) => relativeToAbsolutePos(state, relPos))
      .filter(Boolean) as number[]
  }
}
