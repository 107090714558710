import { HocuspocusProvider } from '@hocuspocus/provider'
import { Extension, findParentNodeClosestToPos } from '@tiptap/core'
import { Plugin, PluginKey } from 'prosemirror-state'
import { Decoration, DecorationSet } from 'prosemirror-view'

import {
  selectIsOtherClientStreaming,
  setOtherClientStreaming,
} from 'modules/ai/stream/reducer'
import { getStore } from 'modules/redux'
import { findSelectionInsideNode } from 'modules/tiptap_editor/utils/selection/findSelectionInsideNode'

import { isCardNode } from '../Card/utils'

const createCursor = () => {
  const cursor = document.createElement('span')

  cursor.classList.add('collaboration-cursor__caret', 'streaming-cursor')
  cursor.setAttribute(
    'style',
    `border-color: var(--chakra-colors-trueblue-100)`
  )

  const label = document.createElement('div')
  label.classList.add('collaboration-cursor__label', 'streaming-cursor__label')
  label.setAttribute(
    'style',
    `background-color: var(--chakra-colors-trueblue-100); color: var(--chakra-colors-trueblue-400)`
  )
  cursor.insertBefore(label, null)

  return cursor
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    aiGeneration: {
      setAIGenerationRunning: (enabled: boolean) => ReturnType
    }
  }
}

type AIGenerationOptions = {
  provider: HocuspocusProvider | null
}

type AIGenerationStorage = {
  generationEnabled: boolean
}

export const AIGeneration = Extension.create<
  AIGenerationOptions,
  AIGenerationStorage
>({
  name: 'aiGeneration',

  addOptions() {
    return {
      provider: null,
    }
  },

  addStorage() {
    return {
      generationEnabled: false,
    }
  },

  onCreate() {
    // setup subscription to awareness changes to keep redux <> awareness in sync
    const { provider } = this.options
    // the second check here is for weirdness in CollaborativeEditor.spec.tsx
    // not properly mocking HocusPocusProvider
    if (!provider || !provider.awareness) {
      return
    }
    provider.awareness.on('change', () => {
      let isOtherClientStreaming: boolean = false

      for (const [clientId, state] of provider.awareness.getStates()) {
        if (state.isStreaming && clientId !== provider.awareness.clientID) {
          isOtherClientStreaming = true
        }
      }

      // only dispatch if the value changes, helps prevent too many dispatches
      // we dont want to debounce this because we need redux to know immediately
      // when another client starts or stops streaming
      const store = getStore()
      const currState = selectIsOtherClientStreaming(store.getState())
      if (currState !== isOtherClientStreaming) {
        store.dispatch(setOtherClientStreaming(isOtherClientStreaming))
      }
    })
  },

  addCommands() {
    return {
      setAIGenerationRunning: (enabled: boolean) => () => {
        this.storage.generationEnabled = enabled
        this.options.provider?.awareness.setLocalStateField(
          'isStreaming',
          enabled
        )
        return true
      },
    }
  },

  addProseMirrorPlugins() {
    const { storage } = this

    return [
      new Plugin({
        key: new PluginKey('aiGeneration'),
        props: {
          // Adds a cursor decoration to the last text position in the doc
          decorations(state) {
            if (!storage.generationEnabled) return DecorationSet.empty

            const decos: Decoration[] = []
            const endOfDoc = state.doc.content.size - 2
            const lastCard = findParentNodeClosestToPos(
              state.doc.resolve(endOfDoc),
              isCardNode
            )
            const $pos = lastCard
              ? findSelectionInsideNode(state.doc.resolve(lastCard.pos), -1)
              : null

            if ($pos && lastCard) {
              decos.push(
                Decoration.widget($pos.from, createCursor, {
                  key: 'sal',
                  side: 10,
                })
              )
              decos.push(
                Decoration.node(
                  lastCard.pos,
                  lastCard.pos + lastCard.node.nodeSize,
                  {
                    class: 'ai-generate-streaming-card',
                  }
                )
              )
            }
            return DecorationSet.create(state.doc, decos)
          },
        },
      }),
    ]
  },
})
