import { Button, Box, Text, VStack, useToast } from '@chakra-ui/react'
import { t, Trans } from '@lingui/macro'
import { useEffect, useState } from 'react'
import Y from 'yjs'

import { SCHEMA_VERSION } from '../schema'

const WarningToast = () => (
  <VStack
    align="flex-end"
    borderRadius="base"
    shadow="lg"
    p={3}
    bg="gray.100"
    spacing={4}
    maxW="300px"
    direction="row"
  >
    <Box>
      <Text fontSize="md" fontWeight="bold">
        <Trans>Gamma needs to reload</Trans>
      </Text>
      <Text fontSize="sm">
        <Trans>
          This page is out of date with the server. To continue editing, you'll
          need to reload this page.
        </Trans>{' '}
        <em>
          <Trans>Don't worry, all of your work is already saved.</Trans>
        </em>{' '}
        😎
      </Text>
    </Box>
    <Box>
      <Button
        size="sm"
        onClick={() => {
          window.location.reload()
        }}
      >
        <Trans>Reload</Trans>
      </Button>
    </Box>
  </VStack>
)

/**
 * A hook to monitor the YDoc instance for
 * updates to the SCHEMA_VERSION YMap. If the CURRENT_VERSION is found
 * to have a newer version than ours, set the outdated and show a toast
 * asking the user to reload before they can continue editing.
 */
export const useSchemaVerifier = (doc?: Y.Doc) => {
  const toast = useToast()

  const [isOutdated, setOutdated] = useState(false)
  useEffect(() => {
    if (!doc) return

    const onDocUpdate = () => {
      const requiredVersion = doc
        .getMap('SCHEMA_VERSION')
        .get('REQUIRED_VERSION') as number
      const outdated = Boolean(
        requiredVersion && requiredVersion > SCHEMA_VERSION
      )
      setOutdated(outdated)
      console[outdated ? 'warn' : 'debug']('[SchemaVerifier] onUpdate', {
        requiredVersion,
        MY_VERSION: SCHEMA_VERSION,
        outdated,
      })
    }
    doc.on('update', onDocUpdate)

    return () => {
      doc.off('update', onDocUpdate)
    }
  }, [doc])

  useEffect(() => {
    if (isOutdated && !toast.isActive(`schema_outdated`)) {
      toast({
        id: `schema_outdated`,
        duration: null,
        status: 'warning',
        isClosable: false,
        position: 'bottom-left',
        render: WarningToast,
      })
    }
  }, [isOutdated, toast])

  return isOutdated
}
