import { Editor } from '@tiptap/core'
import { applyDevTools, removeDevTools } from 'prosemirror-dev-toolkit'
import { useEffect } from 'react'

import { config } from 'config'
import { useFeatureFlag } from 'modules/featureFlags'

export const useProsemirrorDevTools = (editor: Editor | undefined | null) => {
  const enableDebugLogging = useFeatureFlag('debugLogging')

  useEffect(() => {
    if (!editor) return

    if (enableDebugLogging) {
      /**
       *  NB: Prosemirror dev tools triggers an error in the Chrome console that looks like this:
       *
       * react_devtools_backend.js:2574 Warning: render(...): It looks like the React-rendered
       * content of this container was removed without using React. This is not supported and
       * will cause errors. Instead, call ReactDOM.unmountComponentAtNode to empty a container.
       *
       * We think you can safely ignore this, and are documenting here so others don't go down the
       * same rabbithole.
       */
      console.debug('[Prosemirror DevTools] Activating prosemirror-dev-tools')
      try {
        applyDevTools(editor.view)
      } catch (e) {
        // swallow
      }
    } else {
      removeDevTools()
    }

    if (config.DEBUG_ENABLED) {
      window['openPMDT'] = () => applyDevTools(editor.view)
    }
    return () => {
      removeDevTools()
      delete window['openPMDT']
    }
  }, [editor, enableDebugLogging])
}
