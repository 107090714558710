import { Editor, Extension } from '@tiptap/core'
import { Plugin, PluginKey } from 'prosemirror-state'
import * as Y from 'yjs'

const YDocSizePluginKey = new PluginKey<YDocSizeState>('yDocSize')

export const getYDocSize = (editor: Editor): number | null => {
  const state = YDocSizePluginKey.getState(editor.state)
  if (!state) {
    return null
  }
  return state.getYDocSize()
}

/**
 * Extension that allows easy access to the size of the YDoc
 */
export const YDocSizeExtension = Extension.create<{
  document: Y.Doc
}>({
  name: 'yDocSize',

  addProseMirrorPlugins() {
    const { document } = this.options
    return [
      new Plugin({
        key: YDocSizePluginKey,

        state: {
          init() {
            return new YDocSizeState(document)
          },

          apply(_transaction, pluginState) {
            return pluginState
          },
        },
      }),
    ]
  },
})

class YDocSizeState {
  constructor(public ydoc: Y.Doc) {}

  getYDocSize() {
    return Y.encodeStateAsUpdate(this.ydoc).byteLength
  }
}
