import { Extension } from '@tiptap/core'

import { AnimationsPlugin } from './AnimationsPlugin'
import { AnimationsPluginKey } from './AnimationsPluginKey'
import {
  AddAnimationPositions,
  EnableAnimations,
  ResetAnimationPositions,
} from './types'

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    animations: {
      addAnimationPositions: (
        pos: number[],
        isPresentMode: boolean
      ) => ReturnType
      enableAnimations: (enabled: boolean) => ReturnType
      resetAnimationPositions: (isPresentMode: boolean) => ReturnType
    }
  }
}

export const Animations = Extension.create({
  name: 'animations',

  addOptions() {
    return {}
  },

  addCommands() {
    return {
      addAnimationPositions:
        (pos: number[], isPresentMode: boolean) =>
        ({ state, dispatch }) => {
          if (dispatch) {
            state.tr.setMeta(AnimationsPluginKey, <AddAnimationPositions>{
              type: 'addAnimationPositions',
              isPresentMode,
              pos,
            })
          }

          return true
        },

      enableAnimations:
        (enabled: boolean) =>
        ({ state, dispatch }) => {
          if (dispatch) {
            state.tr.setMeta(AnimationsPluginKey, <EnableAnimations>{
              type: 'enableAnimations',
              enabled,
            })
          }

          return true
        },

      resetAnimationPositions:
        (isPresentMode: boolean) =>
        ({ state, dispatch }) => {
          if (dispatch) {
            state.tr.setMeta(AnimationsPluginKey, <ResetAnimationPositions>{
              type: 'resetAnimationPositions',
              isPresentMode,
            })
          }

          return true
        },
    }
  },

  addProseMirrorPlugins() {
    return [AnimationsPlugin()]
  },
})
