import { Plugin } from 'prosemirror-state'

import {
  AiModificationsPluginKey,
  AiModificationsState,
} from './AiModificationsState'

export const AiModificationsPlugin = () =>
  new Plugin({
    key: AiModificationsPluginKey,
    state: {
      init() {
        return new AiModificationsState()
      },
      apply(tr, pluginState, _oldEditorState, newEditorState) {
        return pluginState.apply(tr, newEditorState)
      },
    },
  })
