import {
  Box,
  Center,
  Spinner,
  Stack,
  Text,
  useUnmountEffect,
} from '@chakra-ui/react'
import { Unauthorized } from '@hocuspocus/common'
import { HocuspocusProvider, WebSocketStatus } from '@hocuspocus/provider'
import { Editor, Extension } from '@tiptap/core'
import { Typography } from '@tiptap/extension-typography'
import { memo, useCallback, useEffect, useRef, useState } from 'react'
import * as workerTimers from 'worker-timers'
import * as Y from 'yjs'

import { config } from 'config'
import { Doc, useHealthCheck } from 'modules/api'
import { useFeatureFlag } from 'modules/featureFlags/hooks/useFeatureFlag'
import { useDocLoadTimer } from 'modules/performance/hooks'
import { YDocSizeExtension } from 'modules/tiptap_editor/extensions/YDocSizeExtension'
import { useUserSignedIn } from 'modules/user'

import { EditorCore } from '../EditorCore'
import { AIGeneration } from '../extensions/AI/AIGeneration'
import { AiModificationsExtension } from '../extensions/AI/AiModifications/AiModificationsExtension'
import { Autocomplete } from '../extensions/AI/Autocomplete/Autocomplete'
import { SalExtension } from '../extensions/AI/Sal/SalExtension'
import { Animations } from '../extensions/Animations/Animations'
import { AnnotationExtension } from '../extensions/Annotatable/AnnotationExtension'
import { DraftCommentsExtension } from '../extensions/Annotatable/DraftCommentsExtension'
import { MobileAnnotationExtension } from '../extensions/Annotatable/MobileAnnotationExtension/MobileAnnotationExtension'
import { BlockHoverExtension } from '../extensions/block/BlockHoverExtension'
import { BubbleMenu } from '../extensions/BubbleMenu/bubble-menu-plugin'
import { Collaboration } from '../extensions/Collaboration'
import { CollaborationCursor } from '../extensions/CollaborationCursor'
import { GlobalDragHandle } from '../extensions/DragDrop/GlobalDragHandle'
import { EmojiShortcuts } from '../extensions/Emoji'
import { ExpandableNodes } from '../extensions/ExpandableNodes/ExpandableNodes'
import { Filmstrip } from '../extensions/Filmstrip/Filmstrip'
import { UndoInputRuleKeymap } from '../extensions/keyboard'
import { MediaUpload } from '../extensions/media/Upload'
import { MentionSuggestionMenu } from '../extensions/MentionSuggestionMenu'
import { ScrollMargin } from '../extensions/ScrollMargin'
import { FocusedNodes } from '../extensions/selection/FocusedNodes'
import { SlashMenu } from '../extensions/SlashMenu'
import { Spotlight, useSpotlightAndScrollSync } from '../extensions/spotlight'
import { SCHEMA_VERSION } from '../schema'
import { SelectionDebugger } from '../utils/selection/SelectionDebugger'
import { useAwarenessSync } from './awarenessSync'
import { useDebugPerfLogging } from './debugPerfHooks'
import { useProsemirrorDevTools } from './hooks/useProsemirrorDevTools'
import { initIndexedDB } from './indexedDBPersistence'
import { useDataPersistenceSync } from './persistenceSync'
import { useRateLimiter } from './rateLimiter'
import { useSchemaVerifier } from './SchemaUpdate'
import { IndexeddbPersistence } from './y-indexeddb'

const getParameters = () => ({
  SCHEMA_VERSION: String(SCHEMA_VERSION),
  SHARE_TOKEN: config.SHARE_TOKEN,
})

const MAX_ALLOWED_RATE_LIMIT_VIOLATIONS = 10
const BACKGROUND_DISCONNECT_GRACE_PERIOD = 1000 * 120

export interface CollaborativeEditorProps {
  docId: string
  doc?: Doc
  readOnly?: boolean
  scrollingParentSelector?: string
  onCreate?: (editor: Editor) => void
  hideForPreview?: boolean
  animationsExtensionEnabled?: boolean
}
export const CollaborativeEditor = memo(
  ({
    doc,
    docId,
    onCreate,
    readOnly = false,
    scrollingParentSelector,
    hideForPreview = false,
    animationsExtensionEnabled = false,
  }: CollaborativeEditorProps) => {
    const { timeOnCreate, timeOnSetupProvider, timeOnDownloadDoc } =
      useDocLoadTimer()

    const [connectionStatus, setConnectionStatus] =
      useState<`${WebSocketStatus}`>('connecting')
    const [hasConnected, setHasConnected] = useState(false)
    const hasConnectedRef = useRef(false)
    hasConnectedRef.current = hasConnected
    const [hasSynced, setHasSynced] = useState(false)
    const [persistenceReady, setPersistenceReady] = useState(false)
    const extensions = useRef<Extension[]>([])
    const [editorInstance, setEditorInstance] = useState<Editor>()
    const [ydoc, setYdoc] = useState<Y.Doc>()
    const [yProvider, setProvider] = useState<HocuspocusProvider>()
    const [indexeddbPersistence, setIndexedDBPersistence] =
      useState<IndexeddbPersistence>()

    const isSchemaOutdated = useSchemaVerifier(ydoc)
    useAwarenessSync(editorInstance, yProvider)
    useSpotlightAndScrollSync(editorInstance)
    const [rateLimitFn, isRateLimited, rateLimitHitCount, isBackgrounded] =
      useRateLimiter()
    const orgId = doc && doc.organization && doc.organization.id
    const { isConnected: isOverallConnectionHealthy, setHocuspocusConnected } =
      useHealthCheck()
    const hasDataSyncError = useDataPersistenceSync({
      editor: editorInstance,
      yProvider,
      enabled: isOverallConnectionHealthy && !isBackgrounded && !readOnly,
    })
    const enableDebugLogging = useFeatureFlag('debugLogging')
    const enableSelectionDebugger = useFeatureFlag('debugCardViewed')
    const offlineEditingEnabled = useFeatureFlag('offline')

    const { logMessages, logPerfMessage, dumpLog } =
      useDebugPerfLogging(enableDebugLogging)

    useEffect(() => {
      if (!offlineEditingEnabled || !yProvider || indexeddbPersistence) return

      setIndexedDBPersistence(
        initIndexedDB(docId, yProvider.document, () => {
          const requiredVersion = yProvider.document
            .getMap('SCHEMA_VERSION')
            .get('REQUIRED_VERSION')

          // Consider the IndexedDB sync valid if it has a SCHEMA_VERSION already,
          // which means it was loaded from Hocuspocus successfully at least once before
          if (typeof requiredVersion === 'number') {
            setHasConnected(true)
            setHasSynced(true)
          }
        })
      )
    }, [offlineEditingEnabled, yProvider, docId, indexeddbPersistence])

    useUserSignedIn(
      useCallback(() => {
        // When a user is signed in force Hocuspocus Provider to disconnect and connect
        if (!yProvider) return
        yProvider.disconnect()
        yProvider.configuration.parameters = getParameters()
        setTimeout(() => yProvider.connect(), 500)
      }, [yProvider])
    )

    useProsemirrorDevTools(editorInstance)
    useEffect(() => {
      // Only use the global connection toast during the initial load phase.
      // useDataPersistenceSync will handle verifying the connection after initial setup.
      setHocuspocusConnected(
        hasConnectedRef.current ||
          connectionStatus === 'connected' ||
          isBackgrounded
      )
    }, [setHocuspocusConnected, connectionStatus, isBackgrounded])

    // Reset the Hocuspocus healthcheck state on unmount
    useUnmountEffect(() => setHocuspocusConnected(null), [])

    useEffect(() => {
      if (!yProvider || !hasConnectedRef.current) return

      // Detect reconnecting after the initial connection and call forceSync
      // to ensure that we have the latest YDoc from the server.
      if (connectionStatus === 'connected') {
        console.debug(`[CollaborativeEditor] Force syncing on reconnect.`)
        yProvider.forceSync()
      }
    }, [yProvider, connectionStatus])

    useEffect(() => {
      if (!yProvider) return

      const rateLimitExceeded =
        rateLimitHitCount > MAX_ALLOWED_RATE_LIMIT_VIOLATIONS
      if (isRateLimited) {
        console.warn(
          `[CollaborativeEditor] Attempted to re-connect while rate limited (${rateLimitHitCount} of ${MAX_ALLOWED_RATE_LIMIT_VIOLATIONS}).`
        )
        yProvider.disconnect()
        return
      }
      if (rateLimitExceeded) {
        console.error(
          `[CollaborativeEditor - RECONNECT_RATE_LIMIT] Max rate limit violations reached (${MAX_ALLOWED_RATE_LIMIT_VIOLATIONS}). Will reload.`
        )
        setTimeout(() => {
          window.location.reload()
        }, 500)
        return
      }

      if (!hasConnected) return

      // If we get here, it means we arent rate limited, but one of the
      // rate limiting or background critera has changed.
      // Ensure we are connected or disconnected accordingly
      // Note: AFAICT, provider.connect/disconnect is idempotent
      let onBackgroundTimeout: number
      if (isBackgrounded) {
        console.debug(
          `[CollaborativeEditor] Backgrounded, will wait ${BACKGROUND_DISCONNECT_GRACE_PERIOD}s before disconnect`
        )

        // Allow a grace period for disconnecting.
        onBackgroundTimeout = workerTimers.setTimeout(() => {
          console.debug(
            `[CollaborativeEditor] Disconnecting after grace period`
          )
          yProvider.disconnect()
        }, BACKGROUND_DISCONNECT_GRACE_PERIOD)
      } else {
        console.debug('[CollaborativeEditor] Foregrounded, so re-connecting')
        yProvider.connect()
      }

      return () => {
        if (onBackgroundTimeout) workerTimers.clearTimeout(onBackgroundTimeout)
      }
    }, [
      // The provider shouldnt change after initial load.
      yProvider,
      hasConnected,

      // These dependencies will fire based on rate limiting or backgrounding
      isBackgrounded,
      isRateLimited,
      rateLimitHitCount,
    ])

    // TODO - Pass this data through the core context provider instead.
    useEffect(() => {
      if (editorInstance && !editorInstance.isDestroyed && orgId) {
        editorInstance.commands.setOrgId(orgId)
      }
    }, [editorInstance, orgId])

    useEffect(() => {
      console.debug('[CollaborativeEditor] Creating yProvider for doc', docId)
      const document = new Y.Doc({})
      setYdoc(document)
      document.on('update', (update: Uint8Array) => {
        // console.debug(`[CollaborativeEditor] YDOC update: ${update.length} `)
        // The GammaPersistenceMeta YMap contains metadata from our Hocuspocus extension
        // When the READY key is true, it means our data has been loaded from the API
        const ready = document.getMap('GammaPersistenceMeta').get('READY')

        // Only set this once, as the initial YDoc is all we need to be able to send updates
        // to hocuspocus. On disconnect/reconnect its possible this field toggles to false
        // momentarily, but we dont want to react to that and destroy the editor unnecessarily.
        if (ready) {
          logPerfMessage('Document data downloaded')
          setPersistenceReady(true)
          // console.debug(`[CollaborativeEditor] GammaPersistenceMeta is READY`)
        }
      })

      logPerfMessage('Setting up hocuspocus')

      timeOnSetupProvider()
      const provider = new HocuspocusProvider({
        url: config.MULTIPLAYER_WS_URL,
        name: docId,
        document,
        forceSyncInterval: 15000,
        maxAttempts: 25,
        parameters: getParameters(),
        onStatus({ status }) {
          console.debug('[CollaborativeEditor][onStatus]', { status })
          setConnectionStatus(status)
          if (status === 'connected') {
            logPerfMessage('Hocuspocus connected')
            setHasConnected(true)
          } else if (status === 'connecting') {
            logPerfMessage('Hocuspocus connecting')
            // Rate limit the provider's attempts to connect/disconnect (defaults to 5x per second)
            // Note that this requires a successful connection and then disconnect, which can be caused
            // by a Hocuspocus extension rejecting in its onConnect hook. See #355 for more details
            rateLimitFn()
          }
        },
        onSynced() {
          console.debug('[CollaborativeEditor] Provider synced')
          setHasSynced(true)
        },
        onDisconnect({ event }) {
          console.debug('[CollaborativeEditor][onDisconnect]', { event })
          if (event.code === Unauthorized.code) {
            // Add a check to detect instances of this issue:
            // https://github.com/ueberdosis/hocuspocus/issues/313
            console.error(
              '[CollaborativeEditor] Provider disconnected with "Unauthorized.code", which is unexpected.',
              event
            )
            // For now, we won't do anything. If it starts happening in the wild, we could try and reconnect:
            // setTimeout(() => {
            //   provider.connect()
            // }, 1000)
          }
        },
      })
      setProvider(provider)
      if (config.DEBUG_ENABLED) {
        window['gammaEditorProvider'] = provider
      }

      /**
       * These extensions should NOT impact the underlying prosemirror schema.
       * They are used to facilitate editing/collaboration only.
       */
      extensions.current = [
        ...(animationsExtensionEnabled ? [Animations] : []),
        UndoInputRuleKeymap,
        ScrollMargin,
        Spotlight.configure({ scrollerSelector: scrollingParentSelector }),

        // This plugin includes the core ySync logic, which is required for the cursorPlugin
        // This must run before the cursor plugin, which means it should come above in this list.
        Collaboration.configure({
          document,
        }),

        // Depends on the ySync plugin below
        AIGeneration.configure({
          provider,
        }),
        CollaborationCursor.configure({
          provider,
        }),

        // Annotatable
        AnnotationExtension.configure({ document }),
        MobileAnnotationExtension,
        BlockHoverExtension,
        DraftCommentsExtension.configure(),

        BubbleMenu,
        SlashMenu,
        AiModificationsExtension.configure({ document }),
        SalExtension,
        MentionSuggestionMenu,
        EmojiShortcuts,
        MediaUpload,
        ExpandableNodes,
        FocusedNodes,
        Typography.configure({
          openDoubleQuote: false,
          closeDoubleQuote: false,
          openSingleQuote: false,
          closeSingleQuote: false,
        }),
        GlobalDragHandle.configure({
          scrollerSelector: scrollingParentSelector,
        }),
        Autocomplete,
        Filmstrip,
        YDocSizeExtension.configure({ document }),
      ]

      return () => {
        console.warn(
          '[CollaborativeEditor] Destroying yProvider for doc ➡️',
          docId
        )
        provider.disconnect()
        provider.destroy()
        delete window['gammaEditorProvider']
        setProvider(undefined)
        setHasSynced(false)
        setHasConnected(false)
        setPersistenceReady(false)
        setTimeout(() => {
          // Hack to ensure the awareness provider is destroyed
          // after the editor observable stops listening
          // Not sure why this doesnt get called already
          if (provider && provider.awareness) {
            try {
              provider.awareness.destroy()
            } catch (err) {
              console.warn(
                '[CollaborativeEditor] Error destroying awareness provider.'
              )
            }
          }
        })
      }
    }, [
      // These dependencies are expected to be available immediately and never change
      docId,
      logPerfMessage,
      rateLimitFn,
      scrollingParentSelector,
      timeOnSetupProvider,
    ])

    const isReadyForTipTap = Boolean(
      hasConnected && hasSynced && persistenceReady
    )
    useEffect(() => {
      if (isReadyForTipTap) {
        logPerfMessage('Is ready for TipTap')
        timeOnDownloadDoc()
      }
    }, [isReadyForTipTap, timeOnDownloadDoc, logPerfMessage])

    // console.debug('[CollaborativeEditor] RENDER', {
    //   isReadyForTipTap,
    //   isSchemaOutdated,
    //   isRateLimited,
    //   rateLimitHitCount,
    //   hasConnected,
    //   hasSynced,
    //   persistenceReady,
    //   isOverallConnectionHealthy,
    // })

    const readOnlyBecauseDisconnected = offlineEditingEnabled
      ? false
      : !isOverallConnectionHealthy || hasDataSyncError

    return (
      <Box
        display={hideForPreview ? 'none' : 'block'}
        w="100%"
        data-testid="collaborative-editor-wrapper"
      >
        {isReadyForTipTap && enableSelectionDebugger && (
          <SelectionDebugger editor={editorInstance} />
        )}
        {!editorInstance && (
          <Box
            flexDirection="column"
            flex="1"
            w="100%"
            h="var(--100vh)"
            bg="gray.100"
            zIndex="overlay"
            data-testid="collaborative-editor-skeleton"
            mt={1}
            inset={0}
          >
            <Center h="100%">
              <Stack align="center">
                <Spinner />
                {logMessages.map(({ msg }, ind) => (
                  <Text key={ind}>{msg}</Text>
                ))}
              </Stack>
            </Center>
          </Box>
        )}
        {isReadyForTipTap && (
          <EditorCore
            extensions={extensions.current}
            doc={doc}
            docId={docId}
            editorId="main"
            onCreate={({ editor }) => {
              // A timeout here gives the editor nodeview components
              // a chance to render before we remove the skeleton
              setTimeout(() => {
                // Set the editor instance so that the Collaborative Editor can use it to
                // run editor commands related to collaborative extensions.
                setEditorInstance(editor)

                // Notify the parent that the editor has been created, in case it also needs
                // access to the editor instance so that it can run editor commands
                if (onCreate) {
                  onCreate(editor)
                }

                requestAnimationFrame(() => {
                  logPerfMessage('Editor rendered')
                  timeOnCreate()
                  dumpLog()
                })
              }, 10)
            }}
            readOnly={
              isSchemaOutdated || readOnly || readOnlyBecauseDisconnected
            }
            shouldSupportComments={true}
            shouldSupportMenus={true}
            scrollingParentSelector={scrollingParentSelector}
          />
        )}
      </Box>
    )
  }
)
